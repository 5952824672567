import API from "../../../servicos/API"

import { motion } from "framer-motion"
import { useContext, useEffect, useState } from "react"
import { Spinner } from "../../../componentes/Spinner"
import { useNavigate } from "react-router-dom"

import TopoMenu from '../../../componentes/gerais/TopoMenu'

import { Campo } from "../../../componentes/form/campo"

import { ContextoGeral } from '../../../contextos/ContextoGeral'

import packinfo from '../../../../package.json'
import 'bootstrap'
import { configAnim } from "../../../servicos/config/configAnim"
import { Alert } from "@mui/material"
import AppAPI from "../../../servicos/AppAPI"

export function RecuperarSenha() {
	const [tela, setTela] = useState(1)
	const [tentativas, setTentativas] = useState(0)
	const [isReq, setIsReq] = useState(true)
	const [resposta, setResposta] = useState('')

	const [codigoAPI, setCodigoAPI] = useState('')
	const [codigo, setCodigo] = useState('')
	const [senhaNova, setSenhaNova] = useState('')
	const [senhaNovaConfirmar, setSenhaNovaConfirmar] = useState('')
	const [email, setEmail] = useState('')

	const [redefine, setRedefine] = useState(true)

	const { dados, setDados } = useContext(ContextoGeral)

	const [recovery, setRecovery] = useState({})

	const handleEmail = (event) => {
		setEmail(event.target.value)
	}

	const navigate = useNavigate()

	useEffect(() => {
		if (tela == 1) {
			setRedefine(true)
		}

		setRecovery({
			tela: tela
		})
	}, [tela])




	const handleInput = (event) => {
		console.log(event.target.value)
		switch (event.target.id) {
			case 'senhaNova':
				setSenhaNova(event.target.value)
				break
			case 'senhaNovaConfirmar':
				setSenhaNovaConfirmar(event.target.value)
				break

		}
	}

	function enviarEmail() {
		setResposta(
			<div className="my-3">
				<Spinner />
			</div>
		)

		setEmail(email)
		AppAPI.recuperarSenha(dados, { email: email }).then(res => {
			if (res.resultado) {
				setTela(2)
				setResposta('')
				setCodigoAPI(res.dados.codigo)
			} else {
				setResposta(
					<Alert severity="error" className="mt-3">
						{res.mensagem}
					</Alert>
				)
			}
		}).catch(rej => {
			setResposta(
				<Alert severity="error" className="mt-3">
					Verifique sua conexão
				</Alert>
			)
		})
	}

	useEffect(() => {
		if (tela > 1) {
			verificacao()
		}
	}, [codigo])

	async function verificarCodigo() {
		setResposta(
			<div className="my-3">
				<Spinner />
			</div>
		)

		if (codigo !== parseInt(document.getElementById('codigo').value)) {
			setCodigo(parseInt(document.getElementById('codigo').value))
		} else {
			setResposta(
				<Alert severity="error" className="mt-3">
					Altere o código para continuar
				</Alert>
			)
		}
	}

	function verificacao() {

		if (codigo > 4) {
			if (tentativas < 2) {
				if (codigo === codigoAPI) {
					setResposta('')
					setTela(3)
				} else {
					setTentativas(tentativas + 1)
					setResposta(
						<Alert severity="error" className="mt-3">
							Código inválido, tente novamente
						</Alert>
					)
				}
			} else {
				setTela(1)
				setTentativas(0)
				setResposta(
					<Alert severity="error" className="mt-3">
						Você esgotou suas tentativas, tente novamente
					</Alert>
				)
			}
		} else {
			setResposta(
				<Alert severity="error" className="mt-3">
					Digite o código recebido em seu e-mail
				</Alert>
			)
		}
	}



	function redefinirSenha() {

		setResposta(
			<div className="my-3">
				<Spinner />
			</div>
		)

		/*
		let inputSenha = document.getElementById('senhaNova')
		let inputSenhaConfirmar = document.getElementById('senhaNovaConfirmar')
		*/



		if (senhaNova.length >= 6) {
			if (senhaNova === senhaNovaConfirmar) {
				AppAPI.alterarSenha(dados, {
					email: email,
					novaSenha: senhaNova,
					codigo: codigo
				}).then(res => {
					if (res.resultado) {
						setResposta(<>
							<Alert severity="success" className="mt-3">
								{res.mensagem}
							</Alert>
							<div className="text-center">
								<button className="mt-3" onClick={() => {
									navigate('/login', { msg: res.mensagem })
								}}>
									Voltar para login
								</button>
							</div>
						</>)

						setRedefine(false)
					} else setResposta(<Alert severity="error" className="mt-3">
						{res.mensagem}
					</Alert>)
				}).catch(rej => {
					setResposta(
						<Alert severity="error" className="mt-3">
							Erro ao processar, tente novamente mais tarde.
						</Alert>
					)
				})


			} else {
				setResposta(
					<Alert severity="error" className="mt-3">
						As senhas não conferem!
					</Alert>
				)
			}

		} else {
			setResposta(
				<Alert severity="error" className="mt-3">
					Sua senha deve ter ao menos 6 dígitos
				</Alert>
			)
		}
	}

	return (
		<motion.div
			initial={configAnim.initial}
			animate={configAnim.animate}
			exit={configAnim.exit}
			transition={configAnim.transition}
		>
			{isReq ? (
				<>
					<Spinner color="#000000" />
					{setIsReq(false)}
				</>
			) : (
				<div className='vh-100 d-flex flex-column justify-content-between'>
					<TopoMenu voltar="/login" titulo="RECUPERAR SENHA" />
					{tela === 1 && (
						<motion.div className='mt1 mx-2'
							initial={{
								x: "125%"
							}}
							animate={{
								x: "0%"
							}}
							exit={{
								x: "125%"
							}}
						>
							<p className="textCenter">Digite seu E-mail para continuar</p>
							<Campo
								id="email"
								label="Digite seu E-mail"
								placeholder=" "
								tipo="text"
								value={email}
								onChange={handleEmail}
								autoComplete="email"
							/>
							<motion.div
								initial={{
									opacity: 0
								}}
								animate={{
									opacity: 1
								}}
								exit={{
									opacity: 0
								}}
								transition={{ ease: 'easeInOut', duration: 0.5 }}
							>
								{resposta}
							</motion.div>
							<div className="text-center mt-2">
								<button onClick={enviarEmail}>Enviar Código</button>
							</div>
						</motion.div>
					)}
					{tela === 2 && (
						<motion.div
							className="mx-2"
							initial={{
								opacity: 0
							}}
							animate={{
								opacity: 1
							}}
							exit={{
								opacity: 0,
								x: "125%"
							}}
							transition={{ ease: 'easeInOut', duration: 0.5 }}
						>
							<p className="text-center">Digite o código enviado para seu e-mail</p>
							<Campo
								id="codigo"
								tipo="codigoEmail"
								mask="99999"
							/>
							<p className="text-center">
								<b>
									Você tem {3 - tentativas} tentativa{
										(tentativas > 1) ? ('') : ('s')
									} restante{
										(tentativas > 1) ? ('') : ('s')
									}
								</b>
							</p>

							<motion.div
								initial={{
									opacity: 0
								}}
								animate={{
									opacity: 1
								}}
								exit={{
									opacity: 0
								}}
								transition={{ ease: 'easeInOut', duration: 0.2 }}
							>
								{resposta}
							</motion.div>
							<div className="text-center mt-2">
								<button onClick={verificarCodigo}>Verificar</button>
							</div>
						</motion.div>
					)}
					{tela === 3 && (
						<motion.div
							className="mx-2"
							initial={{
								opacity: 0
							}}
							animate={{
								opacity: 1
							}}
							exit={{
								opacity: 0,
								x: "125%"
							}}
							transition={{ ease: 'easeInOut', duration: 0.5 }}
						>
							<p className="my-3 text-center">
								Muito bem! Agora é só alterar sua senha
							</p>
							<Campo
								id="senhaNova"
								value={senhaNova}
								onChange={handleInput}
								label="Senha"
								tipo="password"
								autoComplete="new-password"
							/>
							<div className="mt-3">
								<Campo
									id="senhaNovaConfirmar"
									value={senhaNovaConfirmar}
									onChange={handleInput}
									label="Confirme sua senha"
									tipo="password"
									autoComplete="new-password"
								/>
							</div>

							<motion.div
								initial={{
									opacity: 0
								}}
								animate={{
									opacity: 1
								}}
								exit={{
									opacity: 0
								}}
								transition={{ ease: 'easeInOut', duration: 0.2 }}
							>
								{resposta}
							</motion.div>
							{redefine
								? <div className="text-center mt-3">
									<button onClick={redefinirSenha}>Redefinir Senha</button>
								</div>
								: ''}
						</motion.div>
					)}
					<p className='textCenter strong'>
						{packinfo.version}v
					</p>
				</div>
			)
			}
		</motion.div >
	)
}